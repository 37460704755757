// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { FaFolder } from "react-icons/fa6";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import useStyles from "./styles";
import "./index.css";
import ROUTES from "../../utils/routes";
import { useNavigate } from "react-router-dom";

type SwiperComponentProps = {
  list: EventCardType[] | [];
};

type EventCardType = {
  id: number;
  image: string;
};

export default function SwiperComponent({ list }: SwiperComponentProps) {
  // const list = [10, 19, 32, 43, 54, 65, 71, 33, 42, 48];

  return (
    <Swiper
      modules={[Navigation]}
      spaceBetween={10}
      slidesPerView={3}
      loop={true}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        // When window width is >= 768px
        768: {
          slidesPerView: 5,
          spaceBetween: 15,
        },
        // When window width is >= 1024px
        1024: {
          slidesPerView: 7,
          spaceBetween: 25,
        },
      }}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper: any) => console.log(swiper)}
      navigation
    >
      {list.map((l) => (
        <SwiperSlide key={l.id}>
          <EventCard image={l.image} id={l.id} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

const EventCard = ({ image, id }: EventCardType) => {
  const { classes: s } = useStyles();
  const navigate = useNavigate();
  return (
    <div
      key={id}
      className={s.root}
      style={{
        backgroundImage: `url(${image})`,
      }}
      onClick={() => navigate(`${ROUTES.EVENT_VIEW_ID}${id}`)}
    >
      <div className={s.eventTitle}>
        <FaFolder />
      </div>
    </div>
  );
};
