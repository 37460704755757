import { useContext, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
// import moment from 'moment';
import useStyles from "./styles";
import "./styles.css";

import AdminCardActions from "./adminActions";
import ROUTES from "../../utils/routes";
import { FaFolder } from "react-icons/fa6";
import { UserContext } from "../../Context";

interface Props {
  id: number;
  blogId: number;
  title: string;
  highlighted: boolean;
  flagged: boolean;
  refetch: () => void;
  imageUrl: string;
  isEvent?: boolean;
}

export default function PostCardContainer(props: Props): JSX.Element {
  const {
    highlighted,
    flagged,
    refetch,
    id,
    imageUrl,
    title,
    isEvent = false,
  } = props;

  const { classes } = useStyles();
  const navigate = useNavigate();

  const [imageLoaded, setImageLoaded] = useState(false);
  // const [hasSeeMore, setHasSeeMore] = useState(false);
  // const [postHided, setPostHided] = useState(post?.hidden);

  const [showOverlay, setOverlay] = useState(false);

  const [showBotttomPart, setShowBottomPart] = useState(false);

  // context
  const { user } = useContext(UserContext);

  const tabIndex = 0;

  const renderImage = () => {
    const url = imageUrl;
    return (
      <img
        // eslint-disable-next-line react/destructuring-assignment
        src={url || ""}
        alt={title || ""}
        onError={() => setImageLoaded(false)}
        onLoad={() => setImageLoaded(true)}
        className={!imageLoaded ? classes.loadingImage : classes.image}
      />
    );
  };

  function routeToIndividualPost(): void {
    if (isEvent) {
      navigate(`${ROUTES.EVENT_VIEW_ID}${id}`);
    } else {
      navigate(`${ROUTES.IMAGE_VIEW_ID}${id}`);
    }
  }

  function handleClick(): void {
    setShowBottomPart(!showBotttomPart);
  }

  // function hideThePost(): void {}

  // function unHideThePost(): void {}

  const isAdmin = user?.isAdmin;
  const isModerator = user?.isModerator;

  const showPostEdit = isAdmin || isModerator;

  return (
    <Box
      className={
        highlighted ? classes.highlightContainer : classes.postCardContainer
      }
    >
      <Box
        className={classes.postCardImageContainer}
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
        tabIndex={tabIndex}
      >
        {/* {imageLoaded ? ( */}
        <div
          className={classes.imageWrapper}
          onMouseEnter={() => setOverlay(true)}
          onMouseLeave={() => setOverlay(false)}
          onClick={() => routeToIndividualPost()}
          role="button"
          onKeyDown={routeToIndividualPost}
          tabIndex={tabIndex}
        >
          {renderImage()}
          {!isEvent && showOverlay && (
            <Box>
              <motion.div
                style={{
                  justifyContent: "flex-end",
                }}
                className={classes.overlay}
                initial={{ opacity: 0, scale: 1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.3,
                }}
              >
                <Typography variant="h6" className={classes.postTitle}>
                  {title}
                </Typography>
              </motion.div>
            </Box>
          )}
          {isEvent && (
            <Box>
              <div
                style={{
                  justifyContent: "flex-start",
                }}
                className={classes.eventOverlay}
              >
                <Typography variant="h6" className={classes.eventtitle}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <FaFolder size={30} color="#8ECAE6" />
                    <Box className={classes.title}>{title}</Box>
                  </Box>
                </Typography>
              </div>
            </Box>
          )}
        </div>
        {/* ) : (
          <Stack spacing={1} sx={{ height: 300 }}>
            <Box sx={{ height: 300 }} />
          </Stack>
        )} */}

        {/* admin buttons */}
        {id && showPostEdit && (
          <AdminCardActions
            postId={props.id}
            blogId={props.blogId}
            refetch={() => refetch()}
            flagged={flagged}
            highlighted={highlighted}
          />
        )}
      </Box>
    </Box>
  );
}
