import { Theme } from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: "100%",
    background: lightBlue[50],
    borderRadius: 8,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    cursor: "pointer",
  },
  eventImage: {
    backgroundSize: "cover",
    width: "100%",
    maxHeight: "100%",
    borderRadius: 8,
  },
  eventTitle: {
    display: "flex",
    alignItems: "center",
    padding: 12,
    fontSize: 30,
    color: theme.palette.primary.light,
  },
  name: {
    fontSize: "1em",
    color: "white",
  },
}));

export default useStyles;
