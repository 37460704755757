import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  eventWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: 24,
    backgroundColor: "rgba(142, 202, 230, 0.15)",
    borderRadius: 6,
    gap: 10,
  },
  link: {
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    cursor: "pointer",
  },
}));

export default useStyles;
