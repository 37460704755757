import { useState } from "react";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";

import useStyles from "./styles";
import { AiOutlineSetting } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import ROUTES, { getEventSlidesPage } from "../../utils/routes";

type Props = {
  setQrModal: () => void;
  setModal: () => void;
  hasQrCode: boolean;
  setMemberModal: () => void;
  setDeleteModal: () => void;
  eventId: number;
};

type OptionsTypes = {
  label: string;
  onClick?: () => void;
  isAdmin?: boolean;
  isModerator?: boolean;
  roles: string[];
};

export default function DropDown({
  setQrModal,
  setModal,
  setMemberModal,
  setDeleteModal,
  hasQrCode,
  eventId,
}: Props) {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { classes: s } = useStyles();
  const navigate = useNavigate();

  const handleOpenSettingsMenu = (
    event: React.MouseEvent<HTMLElement>
  ): void => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null);
  };

  const options: OptionsTypes[] = [
    {
      label: "Download QR",
      onClick: () => {
        setQrModal();
        handleCloseUserMenu();
      },
      roles: ["admin", "moderator", "loggedUser"],
    },
    {
      label: "Generate QR",
      onClick: () => {
        setQrModal();
        handleCloseUserMenu();
      },
      roles: ["admin", "moderator", "loggedUser"],
    },
    {
      label: "Update Event",
      onClick: () => {
        setModal();
        handleCloseUserMenu();
      },
      roles: ["admin", "moderator", "loggedUser"],
    },
    {
      label: "Event Slider",
      onClick: () => {
        navigate(getEventSlidesPage(eventId));
        handleCloseUserMenu();
      },
      roles: ["admin", "moderator", "loggedUser"],
    },
    {
      label: "Members",
      onClick: () => {
        setMemberModal();
        handleCloseUserMenu();
      },
      roles: ["admin", "moderator", "loggedUser"],
    },
    {
      label: "Download Images",
      onClick: () => {
        navigate(`${ROUTES.EVENT_DOWNLOAD_PAGE_WITH_ID}${eventId}`);
        handleCloseUserMenu();
      },
      roles: ["admin", "moderator", "loggedUser"],
    },
    {
      label: "Delete Event",
      onClick: () => {
        setDeleteModal();
        handleCloseUserMenu();
      },
      roles: ["admin", "moderator", "loggedUser"],
    },
  ];

  const getOptions = () => {
    const op = [...options];
    if (hasQrCode) {
      op.splice(1, 1);
    } else {
      op.splice(0, 1);
    }
    return op;
  };

  return (
    <>
      <IconButton
        size="large"
        color="primary"
        className={s.iconButton}
        onClick={(e) => handleOpenSettingsMenu(e)}
      >
        <AiOutlineSetting />
      </IconButton>
      <Box sx={{ flexGrow: 0 }}>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {getOptions().map((op) => (
            <MenuItem
              key={op.label}
              onClick={op.onClick}
              sx={{ bgcolor: "background.default" }}
            >
              <Typography textAlign="center">{op.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
}
