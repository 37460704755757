/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";

import useStyles from "./styles";
import BackgroundScreen from "../../components/BackGroundScreen";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import { gql } from "../../__generated__";
import { UserContext } from "../../Context";
import ROUTES from "../../utils/routes";
import LoginForm from "../../components/LoginForm/LoginForm";
import { LoginFormTypes } from "../../components/LoginForm/LoginFormTypes";
import SendOTPEmail from "../../components/OTP/SendOTPEmail";
import LoginWithOtp from "../../components/OTP/LoginWithOtp";

type Stage = "LOGIN" | "LOGIN_WITH_OTP" | "SEND_OTP";

export default function LogInPage(): JSX.Element {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const [stage, setStage] = useState<Stage>("LOGIN");
  // hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const eventUuid = searchParams.get("eventUuid");

  const { data: getEventData } = useQuery(GET_EVENT_ID, {
    variables: { eventUuid: eventUuid || "" },
    skip: !eventUuid,
  });

  const [joinToEvent, { loading: isJoining }] = useMutation(JOIN_TO_EVENT, {
    onCompleted: (data) => {
      window.location.replace(
        ROUTES.EVENT_VIEW_ID + data.joinToBlogUsingLink.blogId
      );
    },
    onError: (err) => {
      const errMsg = graphqlErrorToMsg(err);
      const blogId = getEventData?.blogIdByEventUuid.blogId;
      if (errMsg === "Already member of given blog" && blogId) {
        window.location.replace(ROUTES.EVENT_VIEW_ID + blogId);
      } else {
        window.location.replace(ROUTES.DASHBOARD);
      }
    },
  });

  const handleCompleteProfileNavigate = () => {
    const pathname = ROUTES.COMPLETE_SIGN_IN;
    if (eventUuid) {
      const search = `?eventUuid=${eventUuid}`;
      navigate(pathname + search);
    } else {
      navigate(pathname);
    }
  };

  const [login, { loading, error }] = useMutation(LOGIN, {
    onCompleted: (apiRes) => {
      const searchParams = new URLSearchParams(location.search);
      const from = searchParams.get("from");

      if (apiRes.login?.token) {
        localStorage.setItem("accessToken", apiRes.login.token);
        // if profile completed and have a UUID joining to event
        if (eventUuid && apiRes.login.account.profile.firstName) {
          joinToEvent({ variables: { uuid: eventUuid } });
        } else if (eventUuid) {
          handleCompleteProfileNavigate();
        } else if (from) {
          const prevUrl = `${window.location.origin}${from}`;
          window.location.replace(prevUrl);
        } else if (apiRes.login?.isAdmin || apiRes.login?.isModerator) {
          window.location.replace(ROUTES.ADMIN_DASHBOARD);
        } else if (apiRes.login.account.profile.firstName) {
          window.location.replace(ROUTES.DASHBOARD);
        } else {
          handleCompleteProfileNavigate();
        }
      }
    },
  });

  const signInUser = (values: LoginFormTypes) => {
    login({ variables: { email: values.userName, password: values.password } });
  };

  const { classes: s } = useStyles();

  useEffect(() => {
    if (userContext?.user?.isCompleted) {
      navigate(ROUTES.DASHBOARD);
    } else if (userContext.user?.userId) {
      navigate(ROUTES.COMPLETE_SIGN_IN);
    }
  }, [userContext, navigate]);

  const goToRegisterPage = () => {
    const pathname = ROUTES.SIGN_UP;
    const search = `?eventUuid=${eventUuid}`;
    if (!eventUuid) {
      navigate(pathname);
    } else {
      navigate(pathname + search);
    }
  };

  if (stage === "LOGIN") {
    return (
      <BackgroundScreen>
        <div className={s.formContainer}>
          <div className={s.formWrapper}>
            <p className={s.title}>Moblog</p>
            <LoginForm
              handleComplete={signInUser}
              loading={loading || isJoining}
            />
            <div className={s.signupContainer}>
              <Box
                className={s.link}
                onClick={() => {
                  navigate("/forgot-password");
                }}
              >
                Forgot your password?
              </Box>
            </div>
            <div className={s.signupContainer}>
              <Box
                className={s.link}
                onClick={() => {
                  setStage("SEND_OTP");
                }}
              >
                Login with OTP
              </Box>
            </div>
            <div className={s.signupContainer}>
              <p>New to Moblog? </p>
              <Box
                className={s.link}
                onClick={() => {
                  goToRegisterPage();
                }}
              >
                Sign up
              </Box>
            </div>
          </div>
        </div>
      </BackgroundScreen>
    );
  }
  if (stage === "SEND_OTP") {
    return (
      <BackgroundScreen>
        <div className={s.formContainer}>
          <div className={s.formWrapper}>
            <p className={s.title}>Moblog</p>
            <SendOTPEmail handleComplete={() => setStage("LOGIN_WITH_OTP")} />
            <div className={s.signupContainer}>
              <Box
                className={s.link}
                onClick={() => {
                  setStage("LOGIN");
                }}
              >
                Login with Password
              </Box>
            </div>
            <Box sx={{ mb: 2 }} />
          </div>
        </div>
      </BackgroundScreen>
    );
  }

  return (
    <BackgroundScreen>
      <div className={s.formContainer}>
        <div className={s.formWrapper}>
          <p className={s.title}>Moblog</p>
          <LoginWithOtp handleComplete={() => {}} />
        </div>
      </div>
    </BackgroundScreen>
  );
}

const LOGIN = gql(`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      isModerator
      isAdmin
      account {
        email
        profile {
          firstName
        }
      }
      token
    }
  }
`);

const JOIN_TO_EVENT = gql(`mutation JoinToBlogUsingLink($uuid:String!){
  joinToBlogUsingLink(uuid:$uuid){
    blogId
  }
}`);

const GET_EVENT_ID = gql(`query blogIdByEventUuid($eventUuid:String!){
  blogIdByEventUuid(eventUuid:$eventUuid){
    blogId
  }
}`);
