import { yupResolver } from "@hookform/resolvers/yup";
import { ApolloError } from "@apollo/client";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";

import ReactHooksInput from "../ReactHooksInput";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import useStyles from "./LoginFormStyles";
import { loginFormSchema, LoginFormTypes } from "./LoginFormTypes";

type LoginFormProps = {
  handleComplete: (values: LoginFormTypes) => void;
  loading: boolean;
  apiError?: ApolloError;
};

export default function LoginForm({
  handleComplete,
  loading,
  apiError,
}: LoginFormProps) {
  const { classes: s } = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm<LoginFormTypes>({
    resolver: yupResolver(loginFormSchema),
    mode: "all",
  });

  return (
    <form onSubmit={handleSubmit(handleComplete)}>
      <ReactHooksInput
        name="userName"
        required
        placeholder="Username / Email"
        register={register}
        error={errors.userName?.message}
        touched={touchedFields.userName}
        autoFocus
      />

      <ReactHooksInput
        name="password"
        required
        inputType="password"
        placeholder="Password"
        register={register}
        error={errors.password?.message}
        touched={touchedFields.password}
      />

      {apiError && (
        <div className={s.errorMsg}>{graphqlErrorToMsg(apiError)}</div>
      )}

      <Button className={s.loginBtn} variant="contained" type="submit">
        {loading ? (
          <CircularProgress sx={{ color: "white" }} size={25} />
        ) : (
          "Log in"
        )}
      </Button>
    </form>
  );
}
