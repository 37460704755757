import Masonry from "react-masonry-css";

import PostCardContainer from "../PostCardContainer";
import useStyles from "./styles";
import PageLoader from "../PageLoader";

interface Props {
  postState?: any[] | null;
  refetch: () => void;
  isEvent?: boolean;
  loading?: boolean;
}

export default function PostContainer(props: Props): JSX.Element {
  const { classes: s } = useStyles();
  const { postState, refetch, isEvent, loading } = props;

  const breakpointColumnsObj = {
    default: 4,
    1200: 3,
    900: 2,
    500: 1,
  };

  if (loading) return <PageLoader />;

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName={`my-masonry-grid_column ${s.gridColumn}`}
    >
      {!isEvent &&
        postState?.map((item) => (
          <PostCardContainer
            key={item?.id}
            imageUrl={
              item?.isShared
                ? item?.originalPost?.media?.[0]?.fileUrl
                : item?.media?.[0]?.fileUrl
            }
            id={item.id}
            blogId={item.blogId}
            title={item?.isShared ? item.shareText : item?.title}
            highlighted={!!item.highlighted?.postId}
            flagged={!!item?.flagged}
            refetch={refetch}
          />
        ))}

      {isEvent &&
        postState?.map((item) => (
          <PostCardContainer
            key={item?.id}
            imageUrl={item?.themeMediaInfo?.url}
            id={item.id}
            blogId={item.blogId}
            title={item?.name}
            highlighted={false}
            flagged={false}
            refetch={refetch}
            isEvent={isEvent}
          />
        ))}
    </Masonry>
  );
}
