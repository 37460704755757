import * as yup from "yup";

export const loginFormSchema = yup
  .object()
  .shape({
    userName: yup.string().required().label("Username"),
    password: yup.string().required(),
  })
  .required();

export type LoginFormTypes = yup.InferType<typeof loginFormSchema>;
