import { Container } from "@mui/system";
import { useParams } from "react-router-dom";

// import Swiper JS
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { gql } from "../../__generated__";
import { useQuery } from "@apollo/client";
import PageLoader from "../../components/PageLoader";
import { Box } from "@mui/material";
import { Navigation, Pagination } from "swiper/modules";

export default function EventsSliderPage() {
  const { eventId } = useParams();

  console.log({ eventId });

  const { data, loading } = useQuery(HIGHLIGHTED_IMAGES, {
    variables: {
      blogId: Number(eventId),
      pagination: {
        nextPage: 1,
        perPage: 20,
      },
    },
    skip: !eventId,
  });

  if (loading) return <PageLoader />;

  const imagesList =
    data?.highlightedPostsByBlogId?.data?.map((post) => ({
      id: post?.postId,
      url: post?.postInfo?.media?.[0]?.fileUrl,
    })) || [];
  return (
    <Container maxWidth="xl">
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        loop
        navigation={true}
        centeredSlides={true}
        pagination={true}
        style={{ height: "90vh" }}
        modules={[Navigation, Pagination]}
        onSlideChange={() => console.log("slide change")}
      >
        {imagesList?.map((img) => (
          <SwiperSlide key={img.id}>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundImage: `URL(${img.url})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  filter: "blur(20px)",
                  zIndex: -1,
                }}
              />
              <img
                src={img.url}
                alt={img.url}
                style={{
                  maxHeight: "90vh",
                  width: "100%",
                  height: "100%",
                  objectFit: "contain", // Ensures image does not overlap or stretch
                }}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
}

const HIGHLIGHTED_IMAGES =
  gql(`query HighlightedImagesByBlogId($blogId:Int!,$pagination:Pagination){
  highlightedPostsByBlogId(blogId:$blogId, pagination:$pagination){
    data{
      postId
      postInfo{
        id
        media{
           id
          fileUrl
        }
      }
    }
  }
}`);
