import { useEffect } from "react";
import { Box, Container } from "@mui/material";
import { toast } from "react-toastify";
import { useLazyQuery } from "@apollo/client";

// import PostContainer from "../../containers/PostContainer";
// import NewsComponent from '../../Components/NewsComponent';
import useStlyes from "./styles";
// import "./styles.css";
import { gql } from "../../__generated__";
import { PostSearchPageEnum } from "../../__generated__/graphql";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import PageLoader from "../../components/PageLoader";
import EventSwiper from "../../components/EventSwiper";
import PostContainer from "../../components/PostContainer";
import fetchMoreData, { checkHasMore } from "../../utils/fetchMore";
import LoadMore from "../../components/LoadMore";
import MessageBox from "../../components/MessageBox";

export default function Dashboard(): JSX.Element {
  const PER_PAGE = 50;
  const { classes } = useStlyes();

  const [getPosts, { loading, data, fetchMore, refetch }] = useLazyQuery(
    POST_LIST,
    {
      fetchPolicy: "network-only",
      onError: (err) => toast.error(graphqlErrorToMsg(err)),
    }
  );

  const fetchData = () => {
    fetchMoreData({
      dataSet: data,
      query: "searchPost",
      variables: {
        searchInfo: { page: PostSearchPageEnum.Events },
      },
      perPage: PER_PAGE,
      fetchMore,
    });
  };

  useEffect(() => {
    getPosts({
      variables: {
        searchInfo: { page: PostSearchPageEnum.Events },
        pagination: { perPage: PER_PAGE, nextPage: 1 },
      },
    });
  }, [getPosts]);

  if (loading) return <PageLoader />;
  if (!loading && !data)
    return (
      <Box className={classes.info}>
        Page did not load properly.
        <br /> Please refresh the page.
      </Box>
    );

  const hasMore = checkHasMore(data, "searchPost");

  if (data) {
    return (
      <Container maxWidth="xl" className={classes.root}>
        <div className={classes.mainDashboardContainer}>
          <EventSwiper />
          <div className={classes.dahsboard}>
            <Box>
              <Box sx={{ flexGrow: 1, my: 6 }}>
                {data.searchPost?.data?.length !== 0 && (
                  <PostContainer
                    postState={data.searchPost?.data}
                    refetch={() => refetch()}
                  />
                )}
                {hasMore && <LoadMore fetchData={fetchData} />}

                {data?.searchPost?.data?.length === 0 && (
                  <MessageBox message="No images to show" />
                )}
              </Box>
            </Box>
          </div>
        </div>
      </Container>
    );
  }
  return <></>;
}

const POST_LIST = gql(`
  query SearchPost($searchInfo: PostsSearchInput!, $pagination:Pagination) {
    searchPost(searchInfo: $searchInfo,pagination:$pagination) {
      paginationDetails{
        currentPage
        lastPage
        totalRecords
      }
      data{
        allowReshare
        blogId
        flagged
        isShared
        shareText
        originalPost{
          id
          media{
            id
            fileUrl
          }
        }
        highlighted{
          postId
        }
        createdAt
        description
        hidden
        id
        media {
          id
          fileUrl
        }
        privacyLevel
        title
        userId
      }
    }
  }`);
