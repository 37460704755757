import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReactHooksInput from "../ReactHooksInput";
import { Box, Button, Typography } from "@mui/material";
import { gql } from "../../__generated__";
import { useMutation } from "@apollo/client";

type SendOTPEmailProps = {
  eventId?: number;
  handleComplete: () => void;
};

export default function SendOTPEmail({
  eventId,
  handleComplete,
}: SendOTPEmailProps) {
  const schema = yup
    .object()
    .shape({
      email: yup.string().required().email().label("Email"),
    })
    .required();

  type FormData = yup.InferType<typeof schema>;

  const [sendEmailOtp] = useMutation(SEND_OTP, {
    onCompleted: () => {
      handleComplete();
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: { email: "" },
    mode: "all",
  });
  const sendOTP = (data: FormData) => {
    sendEmailOtp({
      variables: {
        email: data.email,
        reference: eventId ? String(eventId) : null,
        isEventUser: true,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(sendOTP)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "flex-end",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <ReactHooksInput
            label="Email"
            name="email"
            required
            placeholder="Email"
            register={register}
            // error={errors.email?.message}
            touched={touchedFields.email}
          />
        </Box>

        <Button
          disabled={!!errors.email?.message}
          onClick={() => {}}
          sx={{ mb: 2, width: "100%" }}
          type="submit"
          variant="contained"
        >
          Send
        </Button>
      </Box>
      <div>
        <Typography sx={{ color: "#575757", fontSize: 12, textAlign: "left" }}>
          Please enter your email address, and we will send a passcode to your
          inbox for verification.
        </Typography>
      </div>
      <Box sx={{ mb: 2 }} />
    </form>
  );
}

const SEND_OTP = gql(`
  mutation RequestOtp($email: String!, $reference: String,$isEventUser: Boolean!) {
    requestOtp(email: $email, reference: $reference, isEventUser: $isEventUser) {
      message
    }
  }
`);
