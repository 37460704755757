import React from "react";
import Grid from "@mui/material/Grid";
import { Box, CircularProgress } from "@mui/material";
import useStyles from "./styles";
import BlogActionItem from "../../BlogActionItem";
import { gql } from "../../../__generated__";
import { useQuery } from "@apollo/client";
import { BlogFollower } from "../../../__generated__/graphql";
import UsersCardList from "./UsersCardList";
import fetchMoreData, { checkHasMore, getList } from "../../../utils/fetchMore";
import HighlightPosts from "../../HighlightPosts";
import UserMobsList from "../UserMobList/UserMobList";

interface Props {
  pageOwner: boolean;
  userId: number;
  blogId: number;
}

const PER_PAGE = 20;

export default function ActivitySection(props: Props): JSX.Element {
  const { pageOwner, userId, blogId } = props;
  const { classes } = useStyles();

  // const isAdmin = userDetails?.isAdmin || userDetails?.isModerator;
  const isAdmin = false;
  const {
    data: friendsList,
    loading: isFriendsLoading,
    fetchMore: fetchMoreFriends,
    refetch: refetchFriends,
  } = useQuery(FRIENDS_LIST, {
    variables: { userId, pagination: { perPage: PER_PAGE, nextPage: 1 } },
    fetchPolicy: "network-only",
  });

  const {
    data: mobsList,
    loading: isMobsLoading,
    fetchMore: fetchMoreMobs,
  } = useQuery(USER_MOGS, {
    variables: { userId, pagination: { perPage: PER_PAGE, nextPage: 1 } },
    fetchPolicy: "network-only",
  });

  const {
    data: eventList,
    loading: isEventLoading,
    fetchMore: fetchMoreEvents,
  } = useQuery(USER_EVENTS, {
    variables: { userId, pagination: { perPage: PER_PAGE, nextPage: 1 } },
    fetchPolicy: "network-only",
  });

  const {
    data: highlightList,
    loading: isLoading,
    fetchMore,
  } = useQuery(HIGHLIGHTS_LIST, {
    variables: { userId, pagination: { perPage: PER_PAGE, nextPage: 1 } },
    fetchPolicy: "network-only",
  });

  const {
    data: followersList,
    loading: isFollwersLoading,
    fetchMore: fetchMoreFollowers,
    refetch: refetchFollowers,
  } = useQuery(FOLLOWERS_LIST, {
    variables: { blogId, pagination: { perPage: PER_PAGE, nextPage: 1 } },
    fetchPolicy: "network-only",
  });

  const newHighlightList = highlightList?.highlightedPostsByUserId?.data
    ?.filter((p) => p?.postId)
    .map((x) => x?.postInfo);

  const { data: followingList, fetchMore: fetchMoreFollowing } = useQuery(
    FOLLOWING_LIST,
    {
      variables: { userId, pagination: { perPage: PER_PAGE, nextPage: 1 } },
      fetchPolicy: "network-only",
    }
  );

  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Grid container justifyContent="center" maxWidth="md">
        <Grid item xs={12}>
          <HighlightPosts
            total={
              highlightList?.highlightedPostsByUserId?.paginationDetails
                ?.totalRecords || 0
            }
            loading={isLoading}
            postList={newHighlightList || []}
            hasMore={checkHasMore(friendsList, "latestMembersOnBlog")}
            fetchMore={() =>
              fetchMoreData({
                dataSet: highlightList,
                query: "highlightedPostsByUserId",
                variables: {
                  userId,
                },
                perPage: PER_PAGE,
                fetchMore,
              })
            }
          />

          <UserMobsList
            title="Events"
            loading={isEventLoading}
            total={
              eventList?.memberEventsWhichOwnByGivenUser?.paginationDetails
                ?.totalRecords || 0
            }
            mobList={eventList?.memberEventsWhichOwnByGivenUser.data || []}
            hasMore={checkHasMore(eventList, "memberEventsWhichOwnByGivenUser")}
            fetchMore={() =>
              fetchMoreData({
                dataSet: eventList,
                query: "memberEventsWhichOwnByGivenUser",
                variables: {
                  userId,
                },
                perPage: PER_PAGE,
                fetchMore: fetchMoreEvents,
              })
            }
          />
          <UserMobsList
            title="Mobs"
            loading={isMobsLoading}
            total={
              mobsList?.userOwnMobsWithPrivacy?.paginationDetails
                ?.totalRecords || 0
            }
            mobList={mobsList?.userOwnMobsWithPrivacy.data || []}
            hasMore={checkHasMore(mobsList, "userOwnMobsWithPrivacy")}
            fetchMore={() =>
              fetchMoreData({
                dataSet: mobsList,
                query: "userOwnMobsWithPrivacy",
                variables: {
                  userId,
                },
                perPage: PER_PAGE,
                fetchMore: fetchMoreMobs,
              })
            }
          />
          {(pageOwner || isAdmin) && (
            <div className={classes.wrapper}>
              <p className={classes.subtitle}>Activity</p>
              <div className={classes.descriptionContainer}>
                <div>
                  <p className={classes.subMenu}>Account Activity</p>
                  {isFriendsLoading && <CircularProgress />}
                  {!isFriendsLoading &&
                    Array.isArray(friendsList?.friendsByUserId) &&
                    friendsList?.friendsByUserId.map((item: any) => (
                      <div key={item.id}>
                        <BlogActionItem
                          key={item.id}
                          action={item}
                          isActivityAction
                        />
                      </div>
                    ))}
                </div>

                <div>
                  <p className={classes.subMenu}>Blog Activity</p>
                  {!isFollwersLoading &&
                    followersList?.blogFollowersByBlogId.data?.map(
                      (item: BlogFollower | null) => {
                        if (!item) return <></>;
                        return (
                          <div key={item.userId}>
                            {item.userId}
                            {/* <BlogActionItem action={item} /> */}
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
            </div>
          )}

          <UsersCardList
            isFriends
            label={"Friends"}
            message={"Follow back to add friends"}
            total={
              friendsList?.friendsByUserId.paginationDetails?.totalRecords || 0
            }
            hasMore={checkHasMore(friendsList, "friendsByUserId")}
            fetchMore={() => {
              fetchMoreData({
                dataSet: friendsList,
                query: "friendsByUserId",
                variables: {
                  userId,
                },
                perPage: PER_PAGE,
                fetchMore: fetchMoreFriends,
              });
            }}
            list={getList(friendsList?.friendsByUserId.data, "friendProfile")}
          />
          <UsersCardList
            isFollowing
            label={"Following"}
            total={
              followingList?.followingBlogs?.paginationDetails?.totalRecords ||
              0
            }
            hasMore={checkHasMore(followingList, "followingBlogs")}
            fetchMore={() => {
              fetchMoreData({
                dataSet: followingList,
                query: "followingBlogs",
                variables: {
                  userId,
                },
                perPage: PER_PAGE,
                fetchMore: fetchMoreFollowers,
              });
            }}
            list={getList(followingList?.followingBlogs?.data, "profile")}
          />
          <UsersCardList
            isFollowers
            label={"Followers"}
            total={
              followersList?.blogFollowersByBlogId.paginationDetails
                ?.totalRecords || 0
            }
            hasMore={checkHasMore(followersList, "blogFollowersByBlogId")}
            fetchMore={(isRefetch) => {
              if (isRefetch) {
                refetchFollowers();
                refetchFriends();
              } else {
                fetchMoreData({
                  dataSet: followersList,
                  query: "blogFollowersByBlogId",
                  variables: {
                    blogId,
                  },
                  perPage: PER_PAGE,
                  fetchMore: fetchMoreFollowing,
                });
              }
            }}
            list={getList(
              followersList?.blogFollowersByBlogId?.data,
              "profile"
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

const FRIENDS_LIST = gql(`
query FriendsByUserId($userId:Int!,$pagination: Pagination){
	friendsByUserId(userId:$userId,pagination:$pagination){
    paginationDetails{
      currentPage
      lastPage
      totalRecords
    }
    data{
      userId
      friendId
      createdAt
      friendProfile{
        userId
        firstName
        lastName
        userName
        createdAt
        avatarImage
      }
    }
  }
}`);

const FOLLOWERS_LIST = gql(`
query blogFollowersByBlogId($blogId:Int!,$pagination: Pagination){
  blogFollowersByBlogId(blogId:$blogId,pagination:$pagination){
    paginationDetails{
      currentPage
      lastPage
      totalRecords
    }
    data{
      userId
      profile{
        userId
        firstName
        lastName
        userName
        avatarImage
        createdAt
      }
    }
  }
}`);

const HIGHLIGHTS_LIST = gql(`
query highlightedPostsByUserId($userId:Int!,$pagination: Pagination){
  highlightedPostsByUserId(userId:$userId,pagination:$pagination){
    paginationDetails{
      currentPage
      lastPage
      totalRecords
    }
    data{
      postId
      userId
      blogId
      postInfo {
        id
        userId
        blogId
        title
        description
        privacyLevel
        media {
          id
          postId
          fileUrl
        }
        isShared
        originalPost{
          id
          userId
          blogId
          title
          description
          privacyLevel
          isShared
          media{
            id
            postId
            fileUrl
          }
        }
      }
    }
  }
}`);

const FOLLOWING_LIST = gql(`
query followingBlogs($userId:Int!,$pagination: Pagination){
  followingBlogs(userId:$userId,pagination:$pagination){
    paginationDetails{
      currentPage
      lastPage
      totalRecords
    }
    data{
      id
      userId
      profile{
        userId
        userName
        firstName
        lastName
        avatarImage
      }
    }
  }
}`);

const USER_MOGS =
  gql(`query UserOwnMobsWithPrivacy($userId:Int!,$pagination: Pagination){
  userOwnMobsWithPrivacy(userId:$userId,pagination:$pagination){
    paginationDetails{
      currentPage
      lastPage
      totalRecords
    }
    data{
      id
      userId
      blogType
      name
      themeMediaInfo{
        blogId
        url
      }
    }
  }
}`);

const USER_EVENTS =
  gql(`query MemberEventsWhichOwnByGivenUser($userId:Int!,$pagination: Pagination){
  memberEventsWhichOwnByGivenUser(userId:$userId,pagination:$pagination){
    paginationDetails{
      currentPage
      lastPage
      totalRecords
    }
    data{
      id
      userId
      blogType
      name
      themeMediaInfo{
        blogId
        url
      }
    }
  }
}`);
