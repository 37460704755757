import { gql } from "../__generated__";
import { useQuery } from "@apollo/client";
import PageLoader from "../components/PageLoader";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import UserIcon from "@heroicons/react/24/solid/UserIcon";
import { ReactElement } from "react";
import { PostAddRounded } from "@mui/icons-material";
import DvrIcon from "@mui/icons-material/Dvr";
import {
  blue,
  cyan,
  deepOrange,
  pink,
  purple,
  teal,
} from "@mui/material/colors";

export default function OverviewPage() {
  const { loading, data, error } = useQuery(OVERVIEW_DATA);
  if (loading) return <PageLoader />;
  if (error) return <div>Something went wrong</div>;
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Stack spacing={1}>
            <Typography variant="h4">Overview</Typography>
          </Stack>
        </Stack>
        <Stack spacing={6} direction={"row"} sx={{ py: 4 }}>
          <CardComponent
            label="Users"
            value={data?.backOfficeStatictics?.userCount || 0}
            Icon={<UserIcon />}
            backgroundColor={[blue[300], cyan[100]]}
          />

          <CardComponent
            label="Posts"
            value={data?.backOfficeStatictics?.postsCount || 0}
            Icon={<PostAddRounded />}
            backgroundColor={[purple[300], pink[100]]}
          />
          <CardComponent
            label="Events"
            value={data?.backOfficeStatictics?.eventsCount || 0}
            Icon={<DvrIcon />}
            backgroundColor={[deepOrange[300], teal[100]]}
          />
        </Stack>
      </Container>
    </Box>
  );
}

type CardComponentProps = {
  value: string | number;
  label: string;
  Icon: ReactElement;
  backgroundColor: string[];
};
const CardComponent = ({
  value,
  label,
  Icon,
  backgroundColor,
}: CardComponentProps) => {
  return (
    <Card
      sx={{
        p: 1,
        width: 300,
        display: "flex",
        background: `linear-gradient(to right bottom,${backgroundColor[0]},${backgroundColor[1]})`,
      }}
    >
      <Box sx={{ width: "80%" }}>
        <CardHeader
          title={label}
          sx={{ color: "white" }}
          style={{ fontSize: 24 }}
        />
        <CardContent>
          <Typography sx={{ fontSize: 32, fontWeight: "bold", color: "white" }}>
            {value}
          </Typography>
        </CardContent>
      </Box>
      <Box sx={{ p: 3.5, color: "white" }}>
        <SvgIcon fontSize={"large"}>{Icon}</SvgIcon>
      </Box>
    </Card>
  );
};

const OVERVIEW_DATA = gql(`
  query BackOfficeStatictics {
    backOfficeStatictics {
      userCount
      postsCount
      eventsCount
    }
  }
`);
