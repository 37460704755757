import { toast } from "react-toastify";
import { useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { gql } from "../../__generated__";
import graphqlErrorToMsg from "../../utils/graphqlErrorToMsg";
import SwiperComponent from "../Swiper";
import { BlogTypes } from "../../__generated__/graphql";
import useStyles from "./style";
import PageLoader from "../PageLoader";
import ROUTES from "../../utils/routes";

type EventCard = {
  id: number;
  image: string;
};

export default function EventSwiper() {
  const { classes: s } = useStyles();
  const navigate = useNavigate();
  const { data, loading } = useQuery(MOBS_SEARCH, {
    variables: {
      searchInfo: { ownMobs: false, blogType: BlogTypes.Events },
      pagination: { perPage: 10, nextPage: 1 },
    },
    onError: (err) => toast.error(graphqlErrorToMsg(err)),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const getData = () => {
    if (data?.loggedUserBlogsSearch?.data) {
      const list = data?.loggedUserBlogsSearch?.data;
      const newList = list.map((x) => {
        if (x?.id && x?.themeMediaInfo?.url) {
          return { id: x.id, image: x.themeMediaInfo.url };
        } else {
          return null;
        }
      });
      return newList.filter((x): x is EventCard => !!x);
    }
    return [];
  };

  if (loading)
    return (
      <div className={s.eventWrapper}>
        <PageLoader minHeight={8} />;
      </div>
    );

  if (data?.loggedUserBlogsSearch?.data?.length) {
    return (
      <div className={s.eventWrapper}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontSize={18} color={"text.primary"} fontWeight={500}>
            Recent Events
          </Typography>
          <Typography
            fontSize={14}
            color={"text.primary"}
            fontWeight={500}
            className={s.link}
          >
            {/* <span onClick={() => navigate(ROUTES.EVENTS)}>View All</span> */}
          </Typography>
        </Box>
        <div>
          {data.loggedUserBlogsSearch.data && (
            <SwiperComponent list={getData()} />
          )}
        </div>
      </div>
    );
  }
  return null;
}

const MOBS_SEARCH = gql(`
    query LoggedUserBlogsSearch($searchInfo:LoggedUserBlogsSearchInput!,$pagination:Pagination){
      loggedUserBlogsSearch( searchInfo:$searchInfo,pagination:$pagination){
        paginationDetails{
          currentPage
          lastPage
          totalRecords
        }
        data{
          id
          userId
          blogType
          name
          themeMediaInfo{
            blogId
            themeId
            url
          }
          privacyLevelInfo{
            id
            name
          }
          membersJoiningType
          memberJoiningTypeInfo{
            id
            name
          }
          members{
            userId
            profile{
              userId
              firstName
              lastName
            }
          }
        }
      }
    }
`);
